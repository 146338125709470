$main-bg: white;
$second-bg: skyblue;

body {
  background-color: $main-bg;
  font-size: 16px;
}

/* Globals CSS */
/* dark link helper class */
.dark-link {
  color: #0067b8;
}
i {
  vertical-align: bottom;
}

p {
  font-size: 1.2rem;

  i {
    vertical-align: text-bottom;
  }
}
.btn {
  text-transform: capitalize;
  background: transparent;
  border-radius: 5px;
  color: inherit;
  border: 1px solid black;
  margin-bottom: 5px;
}

.skyblue {
  background-color: $second-bg;
}

.hidden {
  display: none !important;
}

iframe {
  max-width: 100%;
}

/* Navbar */

nav {
  background-color: $main-bg;
  font-size: 1rem;
  color: black;
  border-bottom: 3px solid black;

  img:first-child {
    /* Logo */
    // border-radius: 50%;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    @media only screen and (max-width: 600px) {
      height: 112px;
    }
  }

  ul a,
  .material-icons {
    color: black;
  }

  a:hover {
    border-top: 3px solid $second-bg;
  }
}

.sidenav {
  text-align: center;
  background: rgba($color: white, $alpha: 0.9);
  img {
    border-radius: 50%;
  }
  li a {
    font-size: 1.7rem;
  }
}

/* Sections */
.section {
  padding-top: 1rem;
  display: inherit;
  padding-bottom: 5px;

  .container {
    background-color: $main-bg;
    border-radius: 7px;

    hr {
      color: transparent;
    }
  }
}

.services {
  background-color: #df292d;
  color: white;
  .container {
    background-color: #df292d;
    border: solid white 1px;

    h3.title {
      background-color: #df292d;
      img {
        vertical-align: bottom;
      }
    }
  }
}

.title {
  background-color: $second-bg;
}

.content {
  background-color: $second-bg;
}

/* Sermons */
.sermon {
  @media only screen and (max-width: 600px) {
    margin-bottom: 1.4rem;
    line-height: 1;
  }
}
.sort {
  padding: 2rem 0 1rem 0;
  background-color: #ededed;

  .m3 {
    @media only screen and (max-width: 600px) {
      margin-top: 0.5rem;
    }
  }
}

/* Footer */
.page-footer {
  background-color: rgba(28, 28, 28, 1);
  padding-top: 20px;

  .collection li {
    background-color: inherit;
  }

  a {
    font-size: 1.1em;
  }
}

// Homepage
.welcome {
  background: rgba(0, 0, 0, 0) url("/images/backg.jpg")
    no-repeat scroll center bottom / cover;
  text-align: center;
  padding: 2em 0;

  div {
    background: rgba(15, 26, 25, 0.5) none repeat scroll 0% 0% !important;
    padding: 1rem;
    border-radius: 4rem !important;
    color: $main-bg;

    .btn {
      background-color: $second-bg;
      color: black;
      border-color: transparent;
    }
  }
}
